import compact from 'lodash/compact';
import forIn from 'lodash/forIn';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import pick from 'lodash/pick';
import values from 'lodash/values';
import {
  ChargeMasterDTO,
  BillingMultiMediaUploadDTO,
  BillingMultiMediaResponseDTO,
  InsurancePaymentRequestDTO,
  ChargeMasterDTOPaginatedResponseDTO,
  ClaimBundleDTO,
  SettingsDTO,
  MultiMediaResponseDTO,
  EOBWithInsurancePaymentRequestDTO,
  EOBWithInsurancePaymentResponseDTO,
  NPISearchResponseDTO,
  SplitClaimsDTO,
  CreateSecondaryClaimDTO,
  GetApiChargeSearchCustomChargeMasterParams,
  CustomChargeMasterDTO,
  VisitNoteBillingCustomServiceCodeDTO,
  VisitNoteBillingCodeDTO,
  MarkClaimStatusDTO,
  GetApiBillingGetEOBsParams,
  BulkPatientStatementDTO,
} from 'dtos';
import {
  request,
} from 'services/api';
import {
  formatDateTimeOffset,
} from 'utils/date';
import {
  normalizeCharges,
} from 'pages/Dashboard/pages/Billing/helpers';
import {
  BillingDataParams,
  ChargesRowData,
  ClaimSettings,
  Payment,
  PaymentDataParams,
  ProvidersSearchParams,
} from 'pages/Dashboard/pages/Billing/types';
import {
  compactPatient,
} from 'pages/Dashboard/utils/helper';
import {
  sendRequest,
} from 'utils/httpClient';
import {
  getApiBillingGetEOBs,
  getApiChargeSearchCustomChargeMaster,
  postApiBillingGetBulkPatientStatements,
  postApiBillingGetPatientPayments,
  postApiChargeGetCharges,
} from 'endpoints';
import {
  PaginationState,
} from '@tanstack/react-table';

// adopted for table data call
export const getChargesData = async ({
  pagination,
  dateRange,
  searchQuery,
  setTotalSize,
  requestMedia,
  filterQuery,
}: BillingDataParams): Promise<ChargesRowData[]> => {
  const [start, end] = (dateRange ?? []).map(formatDateTimeOffset);
  const response = await postApiChargeGetCharges({
    dateRange: { start, end },
    patientSearchText: searchQuery,
    filterQuery,
    pageRequest: {
      page: pagination?.pageIndex ?? 0,
      pageSize: pagination?.pageSize ?? 25,
    },
  });
  if (isFunction(requestMedia)) {
    const ids = response.charges?.data?.map(
      (charge) => Number(charge.patient?.patientId),
    ) ?? [];
    requestMedia(ids);
  }

  const meta = response.charges?.pageProperties;
  setTotalSize(meta?.totalSize ?? 0);

  return normalizeCharges(response);
};

export const getBillingCharges = (
  params: BillingDataParams,
): Promise<ChargeMasterDTOPaginatedResponseDTO> => (
  request<ChargeMasterDTOPaginatedResponseDTO>(
    '/charge/searchChargeMaster',
    {
      method: 'GET',
      params: {
        searchComponents: params.searchQuery,
        sortBy: 0,
        page: params.pagination?.pageIndex ?? 0,
        pageSize: params.pagination?.pageSize ?? 25,
      },
    },
  )
);

// adopted for table data call
export const getBillingChargesData = async (
  params: BillingDataParams,
): Promise<ChargeMasterDTO[]> => {
  const response = await getBillingCharges(params);

  params.setTotalSize(response.pageProperties?.totalSize ?? 0);

  return response.data ?? [];
};

export const getCustomServicesData = async ({
  searchQuery,
  pagination,
  setTotalSize,
}: BillingDataParams): Promise<CustomChargeMasterDTO[]> => {
  const response = await getApiChargeSearchCustomChargeMaster({
    searchComponents: searchQuery,
    sortBy: 0,
    page: pagination?.pageIndex ?? 0,
    pageSize: pagination?.pageSize ?? 25,
  } as GetApiChargeSearchCustomChargeMasterParams);

  setTotalSize(response.pageProperties?.totalSize ?? 0);

  return response.data ?? [];
};

// adopted for table data call
export const requestPatientPaymentsData = async ({
  pagination,
  dateRange,
  filterQuery,
  patientSearchText,
  setTotalSize,
  requestMedia,
}: PaymentDataParams): Promise<Payment[]> => {
  const [start, end] = (dateRange ?? []).map(formatDateTimeOffset);
  const response = await postApiBillingGetPatientPayments({
    dateRange: { start, end },
    patientSearchText,
    filterQuery,
    pageRequest: {
      page: pagination?.pageIndex ?? 0,
      pageSize: pagination?.pageSize ?? 25,
    },
  });

  setTotalSize(response?.patientPayments?.pageProperties?.totalSize ?? 0);
  const { billingData } = response ?? {};
  const mappedServicesByEncounter: Record<
    number, Partial<VisitNoteBillingCustomServiceCodeDTO & VisitNoteBillingCodeDTO>[]> = {};

  forIn(billingData ?? {}, ({
    encounterId = 0,
    billingCodes = [],
    customServiceCodes = [],
  }) => {
    if (isNil(mappedServicesByEncounter[encounterId])) {
      mappedServicesByEncounter[encounterId] = [];
    }

    mappedServicesByEncounter[encounterId].push(
      ...(billingCodes ?? []),
      ...(customServiceCodes ?? []),
    );
  });

  const patients = response.patientPayments?.data?.map(
    (payment) => payment?.patient ?? {},
  ) ?? [];

  if (isFunction(requestMedia)) {
    requestMedia(compact(map(patients, 'patientId')));
  }

  return (response?.patientPayments?.data ?? []).map((item) => {
    const encounterIds = (item.patientPaymentByEncounters ?? [])
      .map(({ encounterId = 0 }) => encounterId);
    const mappedServices = compact(values(pick(mappedServicesByEncounter, encounterIds)).flat());

    return ({
      ...item,
      mappedServices,
      patient: compactPatient(item.patient ?? {}),
    });
  });
};

export const getEOBsData = async ({
  pagination,
  dateRange,
  searchText,
  setTotalSize,
}: PaymentDataParams): Promise<EOBWithInsurancePaymentResponseDTO[]> => {
  const [startDate, endDate] = (dateRange ?? []).map(formatDateTimeOffset);
  const {
    pageSize = 25,
    pageIndex: page = 0,
  } = pagination ?? {};
  const response = await getApiBillingGetEOBs({
    startDate,
    endDate,
    searchText,
    page,
    pageSize,
    sortBy: 0,
  } as GetApiBillingGetEOBsParams);

  setTotalSize(response?.pageProperties?.totalSize ?? 0);

  return response.data ?? [];
};

export const uploadBillingMedia = (
  data: BillingMultiMediaUploadDTO,
): Promise<BillingMultiMediaResponseDTO> => (
  request<BillingMultiMediaResponseDTO>(
    '/multimedia/postBillingMultiMedia',
    { method: 'POST', data },
  )
);

export const createEOB = (
  data: EOBWithInsurancePaymentRequestDTO,
): Promise<EOBWithInsurancePaymentResponseDTO> => (
  request<EOBWithInsurancePaymentResponseDTO>(
    '/billing/createEOBWithInsurancePayment',
    {
      method: 'POST',
      data,
    },
  )
);

export const modifyEOB = (
  data: EOBWithInsurancePaymentRequestDTO,
): Promise<EOBWithInsurancePaymentResponseDTO> => (
  request<EOBWithInsurancePaymentResponseDTO>(
    '/billing/modifyEOB',
    {
      method: 'POST',
      data,
    },
  )
);

export const deleteEOB = (id: number): Promise<void> => (
  request<void>(
    '/billing/deleteEOB',
    {
      method: 'DELETE',
      data: { id },
    },
  )
);

export const addEOBPayment = (data: InsurancePaymentRequestDTO): Promise<void> => (
  request<void>(
    '/billing/createInsurancePayment',
    {
      method: 'POST',
      data,
    },
  )
);
export const getClaimSettings = (): Promise<ClaimSettings> => (
  request<SettingsDTO>('/settings/getClaimSettings', { method: 'GET' })
    .then((response) => pick(response, [
      'claimDateQualifierSettings',
      'claimAssignmentSettings',
      'claimInsuranceTypeSettings',
      'claimInsurancePolicySettings',
      'claimResubmissionCodeSettings',
      'claimMeasurementUnitCodeSettings',
      'claimAdjustmentGroupCodeSettings',
    ]))
);

export const createClaim = (encounterId: number): Promise<ClaimBundleDTO> => (
  request<ClaimBundleDTO>(
    `/claim/createClaim/${encounterId}`,
    { method: 'POST' },
  )
);

export const markClaimStatus = (data: MarkClaimStatusDTO): Promise<ClaimBundleDTO> => (
  request<ClaimBundleDTO>(
    '/claim/markClaimStatus',
    { method: 'POST', data },
  )
);

export const saveClaim = (data: ClaimBundleDTO): Promise<ClaimBundleDTO> => (
  request<ClaimBundleDTO>(
    '/claim/saveClaim',
    { method: 'POST', data },
  )
);

export const getClaimSplitsByEncounter = (id: number): Promise<SplitClaimsDTO> => (
  request<SplitClaimsDTO>(
    `/claim/GetSplitByEncounterId/${id}`,
    { method: 'GET' },
  )
);

export const saveClaimSplit = (data: SplitClaimsDTO): Promise<void> => (
  request<void>(
    '/claim/saveSplit',
    { method: 'POST', data },
  )
);

export const createSecondaryClaim = (data: CreateSecondaryClaimDTO): Promise<ClaimBundleDTO> => (
  request<ClaimBundleDTO>(
    '/claim/createSecondaryClaim',
    { method: 'POST', data },
  )
);

export const invokeClaimRequest = (id: number): Promise<ClaimBundleDTO> => (
  request<ClaimBundleDTO>(
    `/claim/transmitClaimToClearinghouse/${id}`,
    { method: 'POST' },
  )
);

export const deleteClaim = (id: number): Promise<void> => (
  request<void>(
    `/claim/deleteClaimById/${id}`,
    { method: 'DELETE' },
  )
);

export const printClaim = (id: number): Promise<MultiMediaResponseDTO> => (
  request<MultiMediaResponseDTO>(
    `/claim/printClaim/${id}`,
    { method: 'POST' },
  )
);

export const getProvidersByParams = (
  params: ProvidersSearchParams,
): Promise<NPISearchResponseDTO> => (
  request<NPISearchResponseDTO>('/Claim/SearchExternalProvider', {
    method: 'GET',
    params,
  })
);

export const getChargesFilterSettings = ():
  Promise<SettingsDTO> => (
  request<SettingsDTO>(
    'Settings/GetChargeFilterSettings',
    { method: 'GET' },
  )
);

export function getLookupOptions(
  optionLookupUrl: string,
  search: string,
) {
  return request<any[]>(optionLookupUrl, {
    method: 'GET',
    params: { search },
  });
}

export const getPatientPaymentFilterSettings = ():
  Promise<SettingsDTO> => (
  sendRequest<SettingsDTO>(
    { url: '/api/Settings/GetPatientPaymentFilterSettings' },
    { method: 'GET' },
  )
);

type StatementsDataParams = {
  dateRange?: Date[];
  pagination?: PaginationState;
  setTotalSize?: (size: number) => void;
};

export async function getStatementList({
  dateRange,
  pagination,
  setTotalSize,
}: StatementsDataParams): Promise<BulkPatientStatementDTO[]> {
  const [start, end] = (dateRange ?? []).map(formatDateTimeOffset);
  const { reportResults } = await postApiBillingGetBulkPatientStatements({
    dateRange: { start, end },
    pageRequest: {
      page: pagination?.pageIndex ?? 0,
      pageSize: pagination?.pageSize ?? 25,
    },
  });

  setTotalSize?.(reportResults?.pageProperties?.totalSize ?? 0);

  return reportResults?.data ?? [];
}

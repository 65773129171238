import capitalize from 'lodash/capitalize';
import forIn from 'lodash/forIn';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import isNil from 'lodash/isNil';
import compact from 'lodash/compact';
import map from 'lodash/map';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import values from 'lodash/values';
import uniqueId from 'lodash/uniqueId';
import {
  formatPhone,
  getFullName,
  getIndexName,
} from 'utils/string';
import {
  formatDate,
  formatDateOnly,
  formatDateTime,
  formatDateTimeOffset,
  getDateRangeString,
  getTimeRangeString,
} from 'utils/date';
import dayjs, {
  Dayjs,
} from 'utils/dayjs';
import {
  isEmptyString,
  notEmptyString,
  setDefaultValueIf,
} from 'utils/misc';
import {
  validateAddress,
} from 'utils/react-hook-form';
import {
  Doctor,
  NormalizedUser,
} from 'pages/Dashboard/pages/Appointments/pages/List/types/event';
import {
  InsuranceFormInfo,
} from 'pages/Dashboard/pages/Appointments/pages/List/types/patient';
import {
  PatientCompact,
} from 'pages/Dashboard/pages/Encounters/types/patient';
import {
  AppointmentDTO,
  InsuranceDTO,
  BlockedTimeSlotDTO,
  ConfirmAppointmentCommuniqueDTO,
  PatientDTO,
  MultiMediaResponse,
  CalendarEventsResponseDTO,
  ModifyAppointmentRequestDTO,
  ProviderAvailabilityDTO,
  WeeklyRecurringAvailabilityDTO,
  DailyRecurringAvailabilityDTO,
  SingleAvailabilityDTO,
  RecurrenceTypes,
  PagePropertiesRequestDTO,
  AppointmentDetailsDTO,
  SingleBlockedTimeSlotDTO,
  DailyRecurringBlockedTimeSlotDTO,
  WeeklyRecurringBlockedTimeSlotDTO,
} from 'dtos';
import {
  CalendarEvent,
} from 'pages/Dashboard/pages/Appointments/pages/List/types/appointment';
import {
  getMultiMedia,
} from 'pages/Dashboard/services/api';
import {
  MediaCategory,
  MediaOriginatorType,
} from 'pages/Dashboard/utils/constants';
import {
  weekDays,
} from 'core/constants';
import {
  MbscRecurrenceRule,
} from '@mobiscroll/react';
import {
  AppointmentForm,
} from 'pages/Dashboard/pages/Appointments/pages/List/components/Details';
import {
  FileResultDTO,
} from 'dtos/fileResultDTO';

const EMAIL_REGEX = /^[\w.-]+@[\w-]+(\.\w{2,})+$/i;
export const PATIENT_NAME_REGEX = /^[^,]*$/;
export function normalizePhoneNumber(phone: string) {
  const phoneNumber = phone?.replace(/\D/g, '');
  return notEmptyString(phoneNumber) && phoneNumber?.length === 11
    ? `+${phoneNumber}` : undefined;
}

export const validateEmail = (email: string): boolean => EMAIL_REGEX.test(email);
export const validatePhone = (phone?: string | null): boolean => {
  const phoneNumber = normalizePhoneNumber(phone ?? '');
  return /^\+\d{11}$/i.test(phoneNumber ?? '');
};
export const validateEmailIfSet = (email: string = '') => isEmptyString(email) || validateEmail(email);
export const validatePhoneIfSet = (phone: string | null = '') => {
  const phoneNumber = normalizePhoneNumber(phone ?? '') ?? '';
  return phoneNumber?.substring(2).length === 0 || validatePhone(phoneNumber);
};
export const validateDateRange = (dateRange: Dayjs[]) => (
  dateRange[0]?.isBefore(dateRange[1]) ?? false
);

type MergedProviderAvailabilityType = ProviderAvailabilityDTO & SingleAvailabilityDTO &
DailyRecurringAvailabilityDTO & WeeklyRecurringAvailabilityDTO;

type MergedTimeBlockType = SingleBlockedTimeSlotDTO
& DailyRecurringBlockedTimeSlotDTO
 & WeeklyRecurringBlockedTimeSlotDTO;

 type MergedRecurrenceType = MergedTimeBlockType & MergedProviderAvailabilityType;

function normalizeEventDateAttributes(startDate: string, durationInMinutes: number = 0) {
  const start = dayjs(startDate);
  const end = dayjs(start).add(durationInMinutes, 'minutes');
  const eventDateRange = [start, end];

  return {
    start,
    end,
    eventDateRange,
    formattedStartDateTime: formatDateTime(start),
    formattedDateRange: getDateRangeString(start, end),
    formattedTimeRange: getTimeRangeString(start, end),
    durationInMinutes,
  };
}

function normalizePatientAttributes(patient: PatientDTO) {
  return {
    patient,
    formattedPatientPhoneNumber: formatPhone(patient?.phoneNumber?.phoneNumberWithAreaCode ?? ''),
    lastVisitDateTime: patient?.visitNotes?.length
      ? formatDateTime(last(patient?.visitNotes)?.visitDateTime ?? '') : null,
  };
}

export function normalizeIntakeFormStatus(
  confirmAppointmentCommunique: ConfirmAppointmentCommuniqueDTO | null = null,
) {
  if (isNil(confirmAppointmentCommunique)) return false;

  const onlySentFormKeys: string[] = [];
  forIn(confirmAppointmentCommunique?.sendForms ?? {}, (prop?: boolean, key?: string) => {
    if (prop) {
      onlySentFormKeys.push(`${key}Submitted`);
    }
  });

  const submissionStatuses = (
    confirmAppointmentCommunique?.intakeSubmissionStatus ?? {}
  ) as Record<string, boolean>;

  return onlySentFormKeys?.every((key: string) => submissionStatuses[key]) ?? true;
}

export function normalizeInsurance(insurance: InsuranceFormInfo): InsuranceDTO {
  const insuranceInfo = omit(insurance, ['insuranceOwnerDOB']);
  return {
    ...(insuranceInfo ?? {}),
    ...setDefaultValueIf(
      insuranceInfo ?? {},
      null,
      isEmptyString,
    ),
    inactive: insurance?.inactive ?? false,
    isPrimary: insurance.isPrimary ?? false,
    insuranceOwnerDOB: formatDateOnly(insurance?.insuranceOwnerDOB ?? dayjs()),
    insuranceOwnerPhoneNumber: normalizePhoneNumber(insurance?.insuranceOwnerPhoneNumber ?? ''),
  };
}

export function normalizeAppointment({
  encounterId,
  userId,
  patient,
  patientAppointmentStatus,
  startDateTime,
  durationInMinutes,
  typeOfVisitDescription,
  reasonForVisit,
  canBeRescheduledSooner,
  confirmAppointmentCommunique,
  insuranceCopayCollection,
  officeLocation,
  referringProvider,
  tag,
  confirmationStatus,
  isOutOfPocket,
  createdByUser,
}: AppointmentDTO): CalendarEvent {
  return {
    id: `visit-${encounterId}`,
    objectId: encounterId!,
    encounterId,
    userId: userId!,
    typeName: 'appointment',
    isTimeBlock: false,
    isTimeAvailability: false,
    typeOfVisitDescription: typeOfVisitDescription ?? '',
    patientAppointmentStatus: patientAppointmentStatus ?? '',
    reason: reasonForVisit ?? '',
    canBeRescheduledSooner,
    ...normalizeEventDateAttributes(startDateTime!, durationInMinutes),
    ...normalizePatientAttributes(patient!),
    createdByUser,
    confirmAppointmentCommunique,
    intakeFormCompleted: normalizeIntakeFormStatus(confirmAppointmentCommunique),
    insuranceCopayCollection,
    officeLocation,
    referringProvider,
    isOutOfPocket,
    tag,
    confirmationStatus,
  };
}

export function normalizeAppointmentWithPayments({
  appointment,
  currentAppointmentBalance,
  payment,
}: AppointmentDetailsDTO) {
  const normalizedAppointment = normalizeAppointment(appointment ?? {});
  return {
    ...normalizedAppointment,
    currentAppointmentBalance,
    payment,
  };
}

function normalizeRecurrence({
  type,
  interval,
  repeatsOn,
  count,
  endsOn,
}: SingleAvailabilityDTO & DailyRecurringAvailabilityDTO & WeeklyRecurringAvailabilityDTO):
MbscRecurrenceRule {
  return {
    ...(type && type?.toLowerCase() !== 'single'
      ? { repeat: type?.toLowerCase() as MbscRecurrenceRule['repeat'] } : {}),
    ...(!isNil(interval) ? { interval } : {}),
    ...(!isNil(count) ? { count } : {}),
    ...(!isNil(endsOn) ? { endsOn: formatDateTimeOffset(endsOn) } : {}),
    ...(!isNil(repeatsOn) ? {
      weekDays: repeatsOn?.map((day) => weekDays[day]).join(','),
      repeatsOn,
    } : {}),
  };
}

export function normalizeRecurringEvents({
  type,
  interval,
  repeatsOn,
  count,
  endsOn,
  userId,
  startDateTime,
  durationInMinutes,
}: MergedRecurrenceType): Omit<CalendarEvent, 'id' | 'objectId'> {
  const recurrence = normalizeRecurrence({
    type, endsOn, interval, repeatsOn, count,
  });

  return {
    userId: userId!,
    ...(type === 'Single' ? {} : { recurrence }),
    ...normalizeEventDateAttributes(startDateTime!, durationInMinutes),
  };
}

export function normalizeTimeBlock({
  blockedTimeSlotId,
  reasonForBlock,
  ...data
}: MergedTimeBlockType): CalendarEvent {
  return {
    ...normalizeRecurringEvents(data),
    id: uniqueId(`blockedTime-${blockedTimeSlotId}__`),
    objectId: blockedTimeSlotId!,
    isTimeBlock: true,
    isTimeAvailability: false,
    typeName: 'timeBlock',
    typeOfVisitDescription: 'Time Blocked',
    reason: reasonForBlock ?? '',
  };
}

export function normalizeAvailability({
  providerAvailabilityId,
  officeLocationId,
  officeLocation,
  isDefault,
  ...data
}: MergedProviderAvailabilityType): CalendarEvent {
  return {
    ...normalizeRecurringEvents(data),
    id: uniqueId(`availability-${providerAvailabilityId}__`),
    objectId: providerAvailabilityId!,
    typeName: 'availability',
    typeOfVisitDescription: 'Availability',
    officeLocationId,
    officeLocation,
    isTimeAvailability: true,
    isTimeBlock: false,
    isDefault,
  };
}

export function normalizeEvents(eventsDictionary: CalendarEventsResponseDTO): CalendarEvent[] {
  const { appointments, blockedTimeSlots, availabilities } = eventsDictionary;

  const normalizedAppointments = appointments?.map(normalizeAppointment);
  const normalizedBlockedTimeSlots = blockedTimeSlots?.map(normalizeTimeBlock);
  const normalizedAvailabilities = availabilities?.map(normalizeAvailability);

  return (normalizedAppointments ?? [])
    .concat((normalizedBlockedTimeSlots ?? []))
    .concat((normalizedAvailabilities ?? []));
}

export function serializeAppointmentForUpdate({
  objectId,
  start,
  durationInMinutes,
  typeOfVisitDescription,
  userId,
  patientAppointmentStatus,
  insuranceCopayCollection,
  reason: reasonForVisit,
  officeLocation,
  referringProvider,
  canBeRescheduledSooner,
  tag,
  isOutOfPocket,
}: Partial<CalendarEvent>): ModifyAppointmentRequestDTO {
  return {
    encounterId: objectId,
    startDateTime: !isNil(start) ? formatDateTimeOffset(start) : undefined,
    reasonForVisit,
    durationInMinutes,
    typeOfVisitDescription,
    patientAppointmentStatus,
    userId,
    insuranceCopayCollection,
    officeLocationId: officeLocation?.addressId,
    referringProvider,
    canBeRescheduledSooner,
    tag,
    isOutOfPocket,
  };
}

export function serializeTimeBlock({
  objectId,
  start,
  durationInMinutes,
  userId,
  reason: reasonForBlock,
  recurrence,
}: Partial<CalendarEvent>): BlockedTimeSlotDTO {
  const {
    repeatType,
    interval,
    repeatsOn,
    endsOn,
    count,
  } = recurrence ?? {};
  return {
    type: (isNil(repeatType)
      ? 'Single'
      : capitalize(repeatType?.id?.toString() ?? '')) as RecurrenceTypes,
    blockedTimeSlotId: objectId,
    startDateTime: !isNil(start) ? formatDateTimeOffset(start) : undefined,
    durationInMinutes,
    userId,
    reasonForBlock,
    ...(notEmptyString(interval) ? { interval: Number(interval) } : {}),
    ...(!isNil(count) ? { count: Number(count) } : {}),
    ...(!isNil(repeatsOn) ? { repeatsOn: map(repeatsOn, 'id') } : {}),
    ...(!isNil(endsOn) ? { endsOn: formatDateTimeOffset(endsOn) } : {}),
  };
}

export function serializeProviderAvailability({
  objectId,
  start,
  userId,
  durationInMinutes,
  officeLocation,
  recurrence,
}: Partial<CalendarEvent> & AppointmentForm): ProviderAvailabilityDTO {
  const {
    repeatType,
    interval,
    repeatsOn,
    endsOn,
    count,
  } = recurrence ?? {};
  return {
    type: (isNil(repeatType)
      ? 'Single'
      : capitalize(repeatType?.id?.toString() ?? '')) as RecurrenceTypes,
    userId,
    durationInMinutes,
    startDateTime: start ? formatDateTimeOffset(start) : undefined,
    officeLocationId: officeLocation?.addressId,
    providerAvailabilityId: objectId,
    ...(notEmptyString(interval) ? { interval: Number(interval) } : {}),
    ...(!isNil(count) ? { count: Number(count) } : {}),
    ...(!isNil(repeatsOn) ? { repeatsOn: map(repeatsOn, 'id') } : {}),
    ...(!isNil(endsOn) ? { endsOn: formatDateTimeOffset(endsOn) } : {}),
  };
}

export const validateDateIfFuture = (date: Dayjs) => !date.isAfter(formatDate(dayjs()), 'date') && date.isValid();
export const validateDate = (date: Dayjs) => !isNil(date) && dayjs(date)?.isValid();

const requiredInsuranceKeys = Object.freeze([
  'insuranceOwnerType',
  'insuranceOwnerFirstName',
  'insuranceOwnerLastName',
  'insuranceOwnerGenderDescription',
  'insuranceOwnerDOB',
  'insuranceName',
  'memberNumber',
]);

export function validateInsurance(insurance: InsuranceDTO) {
  const requiredKeys = compact([
    ...requiredInsuranceKeys,
    /compensation$/i.test(insurance?.insuranceOwnerType ?? '') ? 'employerName' : null,
  ]);

  return validateAddress(insurance?.insuranceOwnerAddress ?? {})
    && validatePhone(insurance?.insuranceOwnerPhoneNumber ?? '--')
    && !values(pick(insurance, requiredKeys)).some(isEmptyString);
}

function getFinalizedNoteCaption(fullName: string, fileName: string = '') {
  const [, date] = fileName.match(/^\d+-visit-(\d{4}-\d{2}-\d{2})/i) ?? [];
  return typeof date !== 'undefined' ? `${fullName} Visit on ${formatDate(date)}` : undefined;
}

export const getInsuranceStatusMessage = (inactive: boolean, lastInactiveDate: string) => (
  inactive && !isEmptyString(lastInactiveDate)
    ? `Deactivated on: ${formatDateOnly(lastInactiveDate)}`
    : '');

export const normalizeFinalizedVisitNotes = (
  media: MultiMediaResponse[],
  patient: PatientCompact,
) => {
  const fullName = getFullName(patient);
  return media.map((item) => ({
    ...item,
    caption: getFinalizedNoteCaption(fullName, item.fileName ?? ''),
  }));
};

export const compactPatient = ({
  patientId,
  dateOfBirth,
  firstName,
  lastName,
  middleName,
  genderDescription,
  address,
  phoneNumber,
  email,
}: PatientDTO): PatientCompact => ({
  patientId,
  dateOfBirth: dateOfBirth ?? '',
  formattedDateOfBirth: formatDate(dateOfBirth ?? ''),
  fullName: getFullName({ firstName, lastName, middleName }),
  firstName: firstName ?? '',
  lastName: lastName ?? '',
  middleName: middleName ?? '',
  genderDescription: genderDescription ?? '--',
  address,
  email: email ?? '',
  phoneNumber: phoneNumber?.phoneNumberWithAreaCode ?? '--',
});

export const normalizeDoctors = (doctors: Doctor[]): NormalizedUser[] => (
  doctors.map((doctor: Doctor) => ({
    ...doctor,
    id: doctor.userId,
    name: getIndexName(doctor),
    nameWithCredentials: getIndexName(doctor, '', true),
  }))
);

export const getPatientProfileImages = (
  ids: (number | undefined)[] = [],
  pageProperties: PagePropertiesRequestDTO = {},
): Promise<MultiMediaResponse[]> => {
  const compacted = compact(ids);

  return compacted.length === 0
    ? Promise.resolve([])
    : getMultiMedia({
      generateUrls: true,
      ...(isNil(pageProperties) || isEmpty(pageProperties)
        ? {}
        : { pageProperties }
      ),
      list: compacted
        .map((id) => ({
          originatorId: id,
          originatorType: MediaOriginatorType.Patient,
          mediaCategory: MediaCategory.ProfilePicture,
        })),
    });
};

export function downloadFile({ fileName, contentType, contents }: FileResultDTO) {
  if (!isNil(contents)) {
    const a = document.createElement('a');
    a.href = compact(['data:', contentType, ';base64,', contents]).join('');
    a.download = fileName ?? '';
    document.body.appendChild(a);
    a.click();
  }
}

import * as signalR from '@microsoft/signalr';
import {
  HubConnection,
  LogLevel,
} from '@microsoft/signalr';
import {
  signalRConfig,
} from 'config/signalRConfig';
import {
  IHttpConnectionOptions,
} from '@microsoft/signalr/src/IHttpConnectionOptions';
import {
  getAccessTokenFromLocalStorage,
} from 'utils/auth';
import {
  CallbackFunction,
  ErrorHandlerFunction,
  LoggerFunction,
  ParamWithCallBackFunction,
} from 'types/commonTypes';
import {
  sleep,
} from 'pages/Dashboard/pages/Encounters/pages/List/utils/helper';
import {
  SignalRConnection,
} from 'core/signalR/connection';
import {
  getFinalEndpointUrl,
} from 'core/signalR/helpers';
import {
  isEmptyString,
} from 'utils/misc';

const hubs = [
  'encounters',
  'todos',
  'todos-comment',
  'square-terminal-payment',
  'reports',
  'patient-statements',
] as const;
export type Hub = typeof hubs[number];

const hubEvents = [
  'OnReceiveToDoCountChange',
  'OnReportComplete',
  'OnBulkPatientStatementComplete',
] as const;
export type HubEvent = typeof hubEvents[number];

const suffixConnectionsOnly: Record<string, SignalRConnection> = {};
const connectionInPendingProcess: Set<string> = new Set<string>();

export function getAccessToken(): string | null {
  const cachedTokenItem = getAccessTokenFromLocalStorage();

  try {
    return cachedTokenItem.token;
  } catch (e) {
    console.error(e);
  }

  return null;
}

const newHubOptions = (): IHttpConnectionOptions => {
  const token = getAccessToken();

  const options: IHttpConnectionOptions = {
    withCredentials: false, // true when we have allowed credentials in the server
    skipNegotiation: false, // keep it false always
    transport: signalR.HttpTransportType.WebSockets,
    accessTokenFactory(): string {
      return token ?? '';
    },
  };

  options.headers = {
    ...(options.headers ?? {}),
    Authorization: `Bearer ${token}`,
  };

  return options;
};

export function newHub(finalEndpoint: string): HubConnection {
  const options: IHttpConnectionOptions = newHubOptions();
  const loggerType = signalRConfig.isLogEnabled
    ? LogLevel.Information
    : LogLevel.Error;

  return new signalR
    .HubConnectionBuilder()
    .withUrl(finalEndpoint, options)
    .withAutomaticReconnect(signalRConfig.retry)
    .configureLogging(loggerType)
    .build();
}

const getLogger = (isLog: boolean): LoggerFunction => (...a: any) => {
  if (!isLog) {
    return;
  }

  console.warn(...a);
};

export const getErrorLogger = (isLog: boolean): LoggerFunction => (...a: any) => {
  if (!isLog) {
    return;
  }

  console.error(...a);
};

const defaultLogger: LoggerFunction = getLogger(signalRConfig.isLogEnabled);

/**
 * Creates a new SignalR hub (every time no caching).
 *
 * @param {boolean} isLog - indicates if logging is enabled
 * @param {string} endPointSuffixOnly - suffix part to use as a key.
 * @param {string} endpointUrl - final endpoint.
 * @param {HubConnection} hub - the hub connection
 * @return {SignalRConnection} a new SignalR hub
 */
function newConnection(
  isLog: boolean,
  endPointSuffixOnly: string,
  endpointUrl: string,
  hub: HubConnection,
): SignalRConnection {
  const logger = getLogger(isLog);
  const errorLogger = getErrorLogger(isLog);

  const defaultErrorHandler: ErrorHandlerFunction = (e) => {
    console.error(`${endpointUrl} -- error`, e);
  };

  return {
    getAllSubscriptionsNames(): string[] {
      return Object.keys(this.subscribers);
    },
    errorLogger,
    logger,
    isSubscribed(name: string): boolean {
      return this.subscribers.has(name);
    },
    isUnsubscribed(name: string): boolean {
      return !this.isSubscribed(name);
    },
    subscribers: new Set<string>(),
    getEndpoint(): string {
      return endpointUrl;
    },
    getEndpointSuffixOnly(): string {
      return endPointSuffixOnly;
    },
    getListener(): Promise<void> {
      return this.connect().listener;
    },
    listener: Promise.resolve(undefined),
    isLog,
    hubConnection: hub,
    errorHandlers: [],
    isConnected: false,
    isDisconnected(): boolean {
      return !this.isConnected;
    },
    connect(): SignalRConnection {
      if (this.isConnected) {
        logger(endpointUrl, 'already connected');

        return this;
      }

      logger(endpointUrl, '-- connecting');
      this.isConnected = true;
      const listener = this.hubConnection.start();
      logger(endpointUrl, '-- started');
      listener.catch(defaultErrorHandler);

      this.errorHandlers.forEach((handler) => {
        listener.catch(handler);
      });

      logger(endpointUrl, '-- error handlers added');
      this.listener = listener;
      this.subscribers = new Set<string>();

      return this;
    },
    disconnect(): SignalRConnection {
      if (!this.isConnected) {
        logger(endpointUrl, '-- not connected yet! Call connect()');

        return this;
      }

      // already connected;
      logger(endpointUrl, '-- disconnecting');
      this.listener = this.hubConnection.stop();
      this.isConnected = false;
      this.subscribers = new Set<string>();
      logger(endpointUrl, '-- disconnected');

      return this;
    },
    addErrorHandler(errHandler: ErrorHandlerFunction): SignalRConnection {
      this.errorHandlers.push(errHandler);

      if (this.isConnected) {
        this.getListener().catch(errHandler);
      }

      return this;
    },
    subscribe(name: string, subscribedFunc: ParamWithCallBackFunction): SignalRConnection {
      if (!this.isConnected) {
        throw new Error(`${endpointUrl} -- ${name} -- cannot subscribe because not connected yet! Call connect() first.`);
      }

      if (isEmptyString(name)) {
        console.warn(`${endpointUrl} -- ${name} -- cannot subscribe because name is empty!`);

        return this;
      }

      logger(`${endpointUrl} -- ${name} -- subscribing`);

      const connection = this;
      this.hubConnection.on(name, (...args: any[]) => {
        subscribedFunc(connection, ...args);

        logger(`invoked subscriptions : ${endpointUrl} -- ${name} -- args:`, ...args);
      });
      this.subscribers.add(name);
      logger(`${endpointUrl} -- ${name} -- subscribed`);

      return this;
    },
    subscribeOnce(
      name: string,
      subscribedFunc: ParamWithCallBackFunction,
    ): SignalRConnection {
      if (!this.isConnected) {
        throw new Error(`${endpointUrl} -- ${name} -- cannot subscribeOnce because not connected yet! Call connect() first.`);
      }

      if (this.subscribers.has(name)) {
        logger(`${endpointUrl} -- ${name} -- already subscribedOnce`);

        return this;
      }

      this.subscribe(name, subscribedFunc);

      return this;
    },
    safeUnsubscribe(
      name: string,
      unsubscribeCallback?: CallbackFunction,
    ): SignalRConnection {
      if (!this.isConnected) {
        throw new Error(`${endpointUrl} -- ${name} -- cannot safeUnsubscribe because not connected yet! Call connect() first.`);
      }

      if (this.subscribers.has(name)) {
        logger(`${endpointUrl} -- ${name} -- unsubscribing`);
        this.hubConnection.off(name);
        this.subscribers.delete(name);
        unsubscribeCallback?.();
        logger(`${endpointUrl} -- ${name} -- unsubscribed`);
      }

      logger(`${endpointUrl} -- ${name} -- already unsubscribed.`);

      return this;
    },
  };
}

export function getSignalRHubConnection(hub: Hub): HubConnection {
  return newHub(getFinalEndpointUrl(hub));
}

/**
 * Creates a SignalR connection and returns it (doesn't create if endpoint already exists).
 *
 * @param {SuffixEndpoints} endPointSuffix
 *  Only the suffix of the signalR endpoint. (e.g. 'chat', 'encounters')
 * @return {SignalRConnection} The connected SignalR connection object
 *  (don't create duplicate connections).
 */
async function getNewOrCachedConnection(
  endpoint: Hub,
): Promise<SignalRConnection> {
  if (endpoint in suffixConnectionsOnly) {
    return suffixConnectionsOnly[endpoint];
  }

  const isInProgress = connectionInPendingProcess.has(endpoint);

  if (isInProgress) {
    defaultLogger(`${endpoint} waiting for previous process to finish. Sleeping for ${signalRConfig.defaultWaitMs} ms and retry.`);
    await sleep(signalRConfig.defaultWaitMs);

    return getNewOrCachedConnection(endpoint);
  }

  connectionInPendingProcess.add(endpoint);
  const finalEndpoint = getFinalEndpointUrl(endpoint);
  const hubConnection = newHub(finalEndpoint);

  const connection = newConnection(
    signalRConfig.isLogEnabled,
    endpoint,
    finalEndpoint, // hubEndpoint
    hubConnection,
  );

  if (connection.isDisconnected()) {
    suffixConnectionsOnly[endpoint] = connection.connect();
  } else {
    suffixConnectionsOnly[endpoint] = connection;
  }

  connectionInPendingProcess.delete(endpoint);

  return connection;
}

export default function getSignalR(hubName: Hub): Promise<SignalRConnection> {
  return getNewOrCachedConnection(hubName);
}

import {
  useContext,
  useEffect,
  useState,
} from 'react';
import isNil from 'lodash/isNil';
import {
  HubConnection,
} from '@microsoft/signalr';
import {
  Hub,
  HubEvent,
} from 'core/signalR';
import {
  SignalRHubContext,
} from 'core/context/signalRHub';

const hubsByEventName: Readonly<Record<HubEvent, Hub>> = Object.freeze({
  OnReceiveToDoCountChange: 'todos',
  OnReportComplete: 'reports',
  OnBulkPatientStatementComplete: 'patient-statements',
});

export function useSignalREffect(
  eventName: HubEvent,
  callback: (...args: any[]) => void,
  dependencies = [],
) {
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const { getHubConnection } = useContext(SignalRHubContext);
  useEffect(() => {
    (async () => {
      const _connection = await (getHubConnection(hubsByEventName[eventName]));
      setConnection(_connection ?? null);
    })();
  }, []);

  useEffect(() => {
    if (!isNil(connection)) {
      connection?.on?.(eventName, callback);
    }
    return () => {
      connection?.off?.(eventName, callback);
    };
  }, [connection, ...dependencies]);
}

import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query';
import isNil from 'lodash/isNil';
import compact from 'lodash/compact';
import map from 'lodash/map';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import {
  getApiSettingsGetAppointmentTypeSettings,
  postApiSettingsAddAppointmentTypeSetting,
  postApiSettingsAddCHPayerConfig,
  putApiSettingsUpdateCHPayerConfig,
  deleteApiSettingsDeleteCHPayerConfig,
  postApiSettingsUpdateAppointmentTypeSetting,
} from 'endpoints';
import {
  AppointmentTypeSettingDTO,
  CHPayerConfigDTO,
  DeleteApiSettingsDeleteCHPayerConfigParams,
} from 'dtos';

export function useUpsertAppointmentType(isNew?: boolean):
  UseMutationResult<AppointmentTypeSettingDTO, Error, AppointmentTypeSettingDTO> {
  return useMutation<AppointmentTypeSettingDTO, Error, AppointmentTypeSettingDTO>(
    (data: AppointmentTypeSettingDTO) => (
      isNew
        ? postApiSettingsAddAppointmentTypeSetting(data)
        : postApiSettingsUpdateAppointmentTypeSetting(data)),
  );
}

export const useGetAppointmentTypeSettings = (
  filterTimeBlock: boolean = false,
): UseQueryResult<AppointmentTypeSettingDTO[], Error> => (
  useQuery<AppointmentTypeSettingDTO[], Error>(
    ['appointment-type-settings'],
    async () => {
      const response = await getApiSettingsGetAppointmentTypeSettings();
      return filterTimeBlock
        ? response.filter((item) => item?.appointmentType !== 'Time Blocked')
        : response;
    },
  )
);

type AppointmentType = {
  appointmentTypes: string[];
  durationByType: Record<string, number>;
};

export const useGetAppointmentTypes = (): AppointmentType => {
  const { data } = useGetAppointmentTypeSettings(true);
  const appointmentTypes = compact(map(data, 'appointmentType'));
  const durationByType = mapValues(
    keyBy(data, 'appointmentType'),
    (item) => item?.defaultDurationInMinutes ?? 15,
  );

  return { appointmentTypes, durationByType };
};

export function useUpsertPayerConfig():
  UseMutationResult<CHPayerConfigDTO, Error, CHPayerConfigDTO> {
  return useMutation<CHPayerConfigDTO, Error, CHPayerConfigDTO>(
    (payload) => (
      isNil(payload?.chPayerConfigId)
        ? postApiSettingsAddCHPayerConfig(payload)
        : putApiSettingsUpdateCHPayerConfig(payload)
    ),
  );
}

export function useDeletePayerConfig():
  UseMutationResult<void, Error, DeleteApiSettingsDeleteCHPayerConfigParams> {
  return useMutation<void, Error, DeleteApiSettingsDeleteCHPayerConfigParams>(
    deleteApiSettingsDeleteCHPayerConfig,
  );
}

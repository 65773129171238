import React, {
  lazy,
} from 'react';
import {
  Navigate,
  RouteObject,
} from 'react-router-dom';
import {
  lazyLoadComponent,
} from 'utils/router';

export const billingRoutes: RouteObject[] = [{
  path: '/billing/claims',
  element: lazyLoadComponent(
    lazy(() => import('./Charges')),
  ),
}, {
  path: '/billing/charge-master/:type',
  element: lazyLoadComponent(
    lazy(() => import('./ChargeMaster')),
  ),
}, {
  path: '/billing/payments/:type',
  element: lazyLoadComponent(
    lazy(() => import('./Payments')),
  ),
}, {
  path: '/billing/statements',
  element: lazyLoadComponent(
    lazy(() => import('./Statements')),
  ),
}, {
  path: '/billing/claims/:id',
  element: lazyLoadComponent(
    lazy(() => import('./ClaimForm')),
  ),
}, {
  path: '/billing',
  element: <Navigate to="/billing/claims" replace />,
}, {
  path: '/billing/charges',
  element: <Navigate to="/billing/claims" replace />,
}, {
  path: '/billing/payments',
  element: <Navigate to="/billing/payments/eobs" replace />,
}, {
  path: '/billing/eobs',
  element: <Navigate to="/billing/payments/eobs" replace />,
}, {
  path: '/billing/charge-master',
  element: <Navigate to="/billing/charge-master/cpt" replace />,
}];

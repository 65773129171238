import compact from 'lodash/compact';
import isNil from 'lodash/isNil';
import memoize from 'lodash/memoize';
import upperFirst from 'lodash/upperFirst';
import values from 'lodash/values';
import {
  AddressDTO,
} from 'dtos/addressDTO';
import {
  isEmptyString,
  notEmptyString,
} from 'utils/misc';
import {
  formatDate,
} from 'utils/date';

export type UserLike = {
  credentials?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  fullName?: string;
  indexName?: string;
};

export function getFullName(
  {
    credentials = '',
    firstName = '',
    lastName = '',
    middleName = '',
    fullName,
  }: UserLike,
  defaultValue: string = '',
  includeCredentials = false,
): string {
  const name = compact(!isEmptyString(fullName)
    ? [fullName]
    : [firstName, middleName, lastName]).join(' ');

  return compact([
    isEmptyString(name) ? defaultValue : name,
    includeCredentials ? credentials : null,
  ]).join(', ');
}

export function getIndexName(
  {
    credentials = '',
    firstName = '',
    lastName = '',
    middleName = '',
    indexName,
  }: UserLike,
  defaultValue: string = '',
  includeCredentials = false,
): string {
  const name = compact(!isEmptyString(indexName)
    ? [indexName]
    : [
      [firstName, middleName].some(notEmptyString)
        && notEmptyString(lastName) ? `${lastName},` : lastName,
      firstName,
      middleName,
    ]).join(' ');

  return compact([
    isEmptyString(name) ? defaultValue : name,
    includeCredentials ? credentials : null,
  ]).join(' ');
}

export function getUserInitials(fullName: string = ''): string | null {
  const parts = fullName.split(' ');
  const result = parts.length >= 3
    ? [parts[0][0], parts[2][0]].join('')
    : compact(parts).map((part) => part[0]).join('');

  return result === '' ? null : result.toUpperCase();
}

export function getAddressString(address?: AddressDTO, includeCountry = false) {
  const {
    street = '',
    unit = '',
    city = '',
    state = '',
    zipCode = '',
    shortZipCode,
  } = address ?? {};
  const cityStateZip = compact([
    city,
    state,
    shortZipCode ?? zipCode,
    includeCountry ? 'USA' : null,
  ]).join(', ');
  return !isNil(address) && !values(address).every(isEmptyString)
    ? `${compact([street, unit, cityStateZip]).join(' ')}`
    : '--';
}

type PluralizeWordProps = {
  word: string;
  isSingular: boolean;
};
const pluralizedExceptions: Readonly<Record<string, string>> = Object.freeze({
  foot: 'feet',
  inch: 'inches',
  Availability: 'Availabilities',
  // extend map if needed
});

const pluralizeWord = memoize(({ word, isSingular }: PluralizeWordProps) => {
  const isException = word in pluralizedExceptions;
  return compact([
    isSingular ? word : null,
    !isSingular && isException ? pluralizedExceptions[word] : null,
    !isSingular && !isException ? `${word}s` : null,
  ]).join('');
});

export function pluralize(
  word: string,
  count: number = 0,
  showCount: boolean = true,
) {
  return compact([
    showCount ? count.toString() : null,
    pluralizeWord({ word, isSingular: Math.abs(count) === 1 }),
  ]).join(' ');
}

export const formatMoney = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format;
type FormatNumberParams = {
  value: string | number;
  fraction?: number;
};
export const formatPercent = memoize(
  ({
    value,
    fraction = 0,
  }: FormatNumberParams) => (
    new Intl.NumberFormat(
      'en-US',
      { style: 'percent', minimumFractionDigits: fraction },
    ).format(Number(value))),
);
export const DEFAULT_REVIEW_OF_SYSTEM_VALUE = 'N/A';
export const getDefaultReviewOfSystemRegex = (
  includePatientDenies?: boolean,
): RegExp => new RegExp(
  `^(${DEFAULT_REVIEW_OF_SYSTEM_VALUE}${includePatientDenies ? '|Patient Denies' : ''})$`,
  'i',
);
const PHONE_REGEX = /^(\+1)?(\d{3})(\d{3})(\d{4})$/i;

export function formatPhone(phone: string) {
  const [match, , areaCode, start, end] = phone
    .replace(/(\s|\(|\)|-)/g, '')
    .match(PHONE_REGEX) ?? [];
  return isNil(match) ? '--' : `(${areaCode}) ${start}-${end}`;
}

export function formatNumber({
  value,
  fraction,
}: FormatNumberParams) {
  return Number(value).toFixed(fraction);
}

const formatTypes = [
  'number',
  'currency',
  'percent',
  'date',
  'phone',
] as const;
export type FormatType = typeof formatTypes[number];

type Formatter = (...params: any[]) => string;

const formatsByType: Readonly<Record<FormatType, Formatter>> = Object.freeze({
  number: formatNumber,
  currency: ({ value }) => formatMoney(value),
  percent: formatPercent,
  date: ({ value }) => formatDate(value),
  phone: ({ value }) => formatPhone(value),
});

export function formatValue(formatType: FormatType, ...params: any[]) {
  return formatsByType[formatType](...params);
}

type CommonReportParameters = {
  Min?: string;
  Max?: string;
  // add more here;
};

const parameterPrefixes = [
  'from',
  'to',
];
export function stringifyReportParameters(parameters: Record<string, any> = {}) {
  const { Min: from, Max: to } = parameters as CommonReportParameters;
  const result = compact([from, to].map((value, index) => (
    isNil(value)
      ? null
      : `${parameterPrefixes[index]} ${formatDate(value ?? '')}`)));
  return result.length > 0 ? upperFirst(result.join(' ')) : null;
}

export function getAllNumbersFromString(
  str?: string | null,
): number[] {
  const matches = (str ?? '').match(/\d+/g) ?? [];
  return matches.map((value) => Number(value ?? 0));
}

import {
  AppointmentTypeSettingDTO,
} from 'dtos/appointmentTypeSettingDTO';
import compact from 'lodash/compact';
import map from 'lodash/map';
import zipObject from 'lodash/zipObject';
import {
  colorClasses,
} from 'pages/Dashboard/pages/Encounters/hooks';
import {
  AppointmentTypeSettings,
} from 'pages/Dashboard/pages/Settings/Appointments/List';

export function assignAppointmentTypeColors(
  appointmentTypes: string[],
): Record<string, string> {
  return {
    ...zipObject(
      appointmentTypes,
      appointmentTypes.map((type, index) => colorClasses[index % appointmentTypes.length]),
    ),
    'Time Blocked': '!bg-rose-300',
  };
}

export function normalizeAppointmentTypes(
  data: AppointmentTypeSettingDTO[],
): AppointmentTypeSettings[] {
  const appointmentTypes = data?.filter((item) => item?.appointmentType !== 'Time Blocked');
  const colors = assignAppointmentTypeColors(
    compact(map(appointmentTypes, 'appointmentType')),
  );

  return appointmentTypes?.map((item) => ({
    ...item,
    color: colors[item?.appointmentType ?? ''],
  }));
}
